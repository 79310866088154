import { Button, MobileStepper } from '@mui/material';
import React, {useState} from 'react';
import BackArrow from './Resources/BackArrow.svg';
import CircleCheck from './Resources/CircleCheck.svg';
import './MobileView.css';
import NoTagsSelectedModal from './NoTagsSelectedModal';

function UploadStep2({ setUploadStep, selectedFilters, setSelectedFilters,listOfTags }) {
  const [showNoTagWarning, setShowNoTagWarning] = useState(false);

  
  const handleTag = (item) => {

    const tempSelectedFilters = [...selectedFilters];

    const tagIndex = tempSelectedFilters.indexOf(item);

    if (tagIndex !== -1) {
      tempSelectedFilters.splice(tagIndex, 1); // Remove the tag
    } else {
      if (tempSelectedFilters.length < 3) {
        tempSelectedFilters.push(item); // Add the tag
      }
    }
      setSelectedFilters(tempSelectedFilters);
  };

  const renderTag = (item) => (
    <span className="content-tag-span" 
    style={{ backgroundColor: selectedFilters.includes(item) ? 'var(--Dark, #222731)' : 'transparent', 
          color: selectedFilters.includes(item) ? '#FFFFFF' : '#222731' }} onClick={() => handleTag(item)}>
      {false ? (
        <>
          <img src={CircleCheck} alt="check" />
          {' '}
          {item.slice(item.indexOf(' ') + 1)}
        </>
      ) : item}
    </span>
  );

  const handleOnClick = () => {
    if (selectedFilters.length === 0) {
      setShowNoTagWarning(true);
    } else {
      setUploadStep(3)
    }
  }

  return (
    <div className="step2-div">
      {showNoTagWarning
      && (
        <NoTagsSelectedModal
          setShowNoTagWarning={setShowNoTagWarning}
          setUploadStep={setUploadStep}
        />
      )}
      <div style={{ display: 'flex', gap: '10px', cursor: 'pointer' }} onClick={() => setUploadStep(1)}>
        <img src={BackArrow} alt="back-arrow" />
        <span className="back-btn">Back</span>
      </div>
      <div className="step2-div-2">
        <span className="div-2-span-2">Add Tags to Your Video</span>
        <span className="div-2-span-3 mt-3 px-[70px] box-border leading-4">
          Select at least one tag that relates to your content
        </span>
        <div className="step2-div-3">
        { <div
                className="flex overflow-hidden mb-4 justify-center flex-wrap gap-[10px] px-3"
              >
                {listOfTags.map((tag) => (
                  (renderTag(tag?.title))
                ))}
              </div>}
        </div>
      </div>
      <div>
        <Button
          className="upload-btn" 
          onClick={() => handleOnClick()}
        >
          Next
        </Button>
        <MobileStepper
          variant="dots"
          steps={3}
          position="static"
          activeStep={1}
          sx={{ maxWidth: 400, flexGrow: 1 }}
          className="step-2-mobile-stepper"
        />
      </div>
    </div>
  );
}

export default UploadStep2;
